/* Import Tailwind CSS classes */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Google Fonts */

@import url("https://fonts.googleapis.com/css2?family=PT+Serif&family=Poppins&display=swap");

/* Custom CSS */

@layer base {
  body {
    @apply font-poppins;
  }

  h1 {
    @apply font-pt;
  }

  .hero-bg {
    background-image: url("/src/assets/home/hero.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .about-bg {
    background-image: url("/src/assets/about/about-image.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 767px) {
    .hero-bg {
      background-position: right;
    }
  }
}
